import React, { useEffect, useRef, useReducer } from 'react';
import axios from 'axios';
import moment from 'moment';
import ReportMessageItem from './messages/ReportMessageItem.jsx';
import RemoteMessageItem from './messages/RemoteMessageItem.jsx';
import MassMessage from './messages/MassMessage.jsx';
import FilterItem from './messages/FilterItem.jsx';
import Pager from './messages/Pager.jsx';
import DatePicker from './DatePicker.jsx';

function MessagesContainer(props) {
  const [state, setState] = useReducer(
    (prev_state, new_state) => ({ ...prev_state, ...new_state }),
    {
      search_string: props.search_string || '',
      with_deleted: props.with_deleted || false,
      start_date: props.start_date,
      end_date: props.end_date,
      selected_type: props.default_type,
      messages: props.messages,
      selected_page: props.current_page || 1,
      total_pages: props.total_pages,
    }
  );

  const is_first_run = useRef(true);

  const load_selected_page = (page) => {
    const {
      selected_type,
      search_string,
      with_deleted,
      start_date,
      end_date,
    } = state;

    axios.get(props.messages_path, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      params: {
        type: selected_type,
        search_string,
        with_deleted,
        start_date,
        end_date,
        page,
      },
    }).then(({ data:{ messages: new_messages, current_page, total_pages, actual_url } }) => {
      setState({
        messages: new_messages,
        selected_page: current_page,
        total_pages,
      });

      window.history.pushState({}, '', actual_url);
    }).catch(error => console.log(error));
  };

  const set_start_date = date => setState({ start_date: date });
  const set_end_date = date => setState({ end_date: date });

  const select_page_by_user_type = (page) => {
    if (props.is_message_admin) return load_selected_page(page);

    return setState({ selected_page: page });
  };

  const update_read_by_state = (message_id) => {
    const message = state.messages.find(({ id }) => id === message_id);
    const new_message = Object.assign(message, { read_state: { read_by_user: true, unread_replies_count: message.read_state.unread_replies_count } });
    const new_messages = Object.assign(state.messages, new_message);
    setState({ messages: new_messages });
  };

  const apply_date_filter = (messages) => {
    if (typeof state.start_date === 'undefined' && typeof state.end_date === 'undefined') return messages;

    const new_messages = messages.filter(({ last_message_day }) => (
      moment(last_message_day) >= moment(state.start_date || moment('19700101')).toDate()
        && moment(last_message_day) <= moment(state.end_date).toDate()
    ));

    return new_messages;
  };

  const apply_type_filter = (messages) => {
    if (state.selected_type === 'all') return messages;

    return messages.filter(({ type }) => type === state.selected_type);
  };

  const apply_string_filter = (messages) => {
    if (!state.search_string.length) return messages;

    return messages.filter(({ title, sub_title }) => {
      const regex = new RegExp(state.search_string, 'i');
      return regex.test(`${title} ${sub_title}`);
    });
  };

  const apply_deleted_filter = (messages) => {
    if (state.with_deleted) return messages;
    return messages.filter(item => !item.deleted);
  };

  const paginate = (messages) => {
    if (props.is_message_admin) return messages;

    return messages.slice((state.selected_page - 1) * props.items_per_page, state.selected_page * props.items_per_page);
  };

  const filter_messages = () => {
    if (props.is_message_admin) {
      return apply_deleted_filter(state.messages);
    }
    let filtered_messages = apply_date_filter(state.messages);
    filtered_messages = apply_type_filter(filtered_messages);
    filtered_messages = apply_string_filter(filtered_messages);

    return filtered_messages;
  };

  const message_item = (message) => {
    if (['report', 'report_of_death', 'billing_complaint'].includes(message.type)) return <ReportMessageItem key={message.id} update_read_by_state={update_read_by_state} {...message} />;

    return <RemoteMessageItem key={message.id} update_read_by_state={update_read_by_state} {...message} />;
  };

  const select_type = (type) => {
    setState({ selected_type: type });
    const new_param = `?type=${type}`;
    window.history.pushState({}, '', new_param);
  };

  useEffect(() => {
    if (is_first_run.current) {
      is_first_run.current = false;
      return;
    }

    if (props.is_message_admin) return load_selected_page(1);
  }, [
    state.selected_type,
    state.search_string,
    state.start_date,
    state.end_date,
  ]);

  const EmptyState = () => <div className="container container--main py4">
    <div className="center">
      <img src="/icons/file-empty.svg" alt="icon" />
      <p className="fs-13 text-mine-shaft-o-64">Nincs találat</p>
    </div>
  </div>;

  const filtered_messages = filter_messages();
  const is_selected = type => (state.selected_type === type);
  const total_pages = state.total_pages || Math.ceil(filtered_messages.length / props.items_per_page);
  const paginated_messages = paginate(filtered_messages);

  return <>
    <section>
      <div className="container container--main pt6">
        <div className="mb-40 sm-block">
          <h2 className="title sm-mb2 mb-40">
            Üzeneteim
            { (state.search_string.length || state.start_date || state.end_date) && <span className="italic fs-13 ml1">(Szűrt lista)</span> }
          </h2>

          { props.mass_messages_path && <MassMessage mass_messages_path={props.mass_messages_path} partners={props.multi_partners}  /> }
          <div className="flex mt3">
            <div className="mr2">
              <span className="text-mine-shaft-o-64">&nbsp;</span>
              <div className="flex flex-center relative mb2">
                <i className="fal fa-search absolute left-0 ml1 top-50p transform-vertical-center" />
                <input
                  className="input"
                  type="text"
                  placeholder="Keresés a tárgyban..."
                  onChange={e => setState({ search_string: e.target.value })}
                  style={{ paddingLeft: 32 }}
                  value={state.search_string}
                  maxLength="25"
                />
              </div>
            </div>

            <div className="mr2">
              <span className="text-mine-shaft-o-64">Mikortól</span>
              <div className="flex flex-center relative">
                <i className="fal fa-calendar absolute h3 left-0 mx1 text-mine-shaft-o-64 top-50p transform-vertical-center" />
                <DatePicker
                  selected_date={state.start_date}
                  set_date={set_start_date}
                  input_props={{ className: 'input input--pl3' }}
                />
              </div>
            </div>

            <div className="mr2">
              <span className="text-mine-shaft-o-64 mt1">Meddig</span>
              <div className="flex flex-center relative">
                <i className="fal fa-calendar absolute h3 left-0 mx1 text-mine-shaft-o-64 top-50p transform-vertical-center" />
                <DatePicker
                  selected_date={state.end_date}
                  set_date={set_end_date}
                  input_props={{ className: 'input input--pl3' }}
                />
              </div>
            </div>
            { props.is_message_admin && <div>
              <span className="text-mine-shaft-o-64 mt1">Törölt üzenetek is</span>
              <div className="flex flex-center relative">
                <input
                  className="custom-checkbox hidden"
                  id="delete_filter"
                  type="checkbox"
                  // onClick={e => setState({ with_deleted: e.target.checked })}
                  onChange={e => {
                    setState({ with_deleted: e.target.checked });
                    filter_messages();
                  }}
                />
                <label className="custom-checkbox-label" htmlFor="delete_filter">&nbsp;</label>
              </div>
            </div> }

          </div>
        </div>
        <div className="flex flex-wrap fs-13 mb-40">
          {
            props.type_filters.map(({ type, label }) => <FilterItem
              key={type}
              type={type}
              label={label}
              selected={is_selected(type)}
              set_type={select_type}
            />)
          }
        </div>
      </div>
    </section>

    <section className="bg-athens-gray">
      <div className="container container--main py3">
        <div className={`bg-${paginated_messages.length ? 'white' : 'athens-gray'} rounded-custom-8`}>
          {
            !!paginated_messages.length && (paginated_messages.map(message => message_item(message))) || <EmptyState />
          }
        </div>
      </div>
    </section>
    <div className="container container--main pt3 pb4">
      {
        total_pages > 1 && <Pager
          current_page={state.selected_page}
          total_pages={total_pages}
          select_page={select_page_by_user_type}
        />
      }
    </div>
  </>;
}

export default MessagesContainer;
