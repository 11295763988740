import React, { useReducer, useState, useCallback } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import { TrixEditor } from 'react-trix';


function MassMessage({ mass_messages_path, partners }) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      subject: '',
      message: '',
      recipients: [],
      can_submit: false,
      show_message_modal: false,
      show_partners_select: false,
      show_confirmation_modal: false,
      uploadedFiles: [],
    }
  );

  const {
    subject,
    message,
    can_submit,
    recipients,
    show_message_modal,
    show_partners_select,
    show_confirmation_modal,
    uploadedFiles,
  } = state;

  const handleDrop = (acceptedFiles) => {
    // Logic for handling the dropped files
    setState({ uploadedFiles: acceptedFiles });
  };
  // DROPZONE STUFF
  const onDrop = useCallback((acceptedFiles) => {
    setState({ uploadedFiles: acceptedFiles });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const files = uploadedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const show_mass_message_flash = (flash_message) => {
    const message_element = document.querySelector('.js-mass-message-modal .message');
    message_element.textContent = flash_message;

    showModal('.js-mass-message-modal');
    setTimeout(() => closeModal(), 3000);
  };

  const send_message = () => {
    setState({ can_submit: false });

    const csrf_token = document.querySelector('meta[name=csrf-token]').content;

    const formData = new FormData();
    for (let i = 0; i < uploadedFiles.length; i += 1) {
      formData.append('files[]', uploadedFiles[i]);
    }

    for (let i = 0; i < recipients.length; i += 1) {
      formData.append('recipients[]', recipients[i].value);
    }
    formData.append('subject', subject);
    formData.append('message', message);

    axios.post(mass_messages_path, formData, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrf_token,
      },
    }).then(() => {
      setState({
        can_submit: true,
        subject: '',
        message: '',
        recipients: [],
        show_message_modal: false,
        show_partners_select: false,
        show_confirmation_modal: false,
      });

      show_mass_message_flash('Üzenetek elküldve');
    }).catch(() => {
      setState({
        can_submit: true,
        subject: '',
        message: '',
        recipients: [],
        show_message_modal: false,
        show_partners_select: false,
        show_confirmation_modal: false,
      });

      show_mass_message_flash('Hiba üzenetküldés közben!');
    });
  };
  const update_subject = content => setState({ subject: content });
  const update_message = content => setState({ can_submit: !!content.length, message: content });
  const update_recipients = values => setState({ recipients: values });

  const handleChange = (html) => {
    update_message(html);
    // html is the new html content
    // text is the new text content
  };

  return (
    <div>
      <div className="float left">
        <button
          type="button"
          onClick={() => setState({ show_message_modal: true, show_partners_select: false, uploadedFiles: [] })}
          className="btn btn--primary-cerulean mx2 sm-m0 sm-mb2"
        >
          Üzenet mindenkinek
        </button>
      </div>
      <div className="flex">
        <button
          type="button"
          onClick={() => setState({ show_message_modal: true, show_partners_select: true, uploadedFiles: [] })}
          className="btn btn--primary-cerulean mx2 sm-m0 sm-mb2"
        >
          Üzenet
        </button>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={show_message_modal}
        closeTimeoutMS={200}
        onRequestClose={() => setState({ show_message_modal: false })}
        shouldCloseOnOverlayClick
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 6,
          },
          content: {
            inset: '40px',
            width: '50%',
            top: '35%',
            margin: '0 auto',
          },
        }}
      >
        <div style={{ height: '100%' }}>
          <a
            href="#close"
            className="absolute mr2 mt2 right-0 top-0"
            onClick={(e) => {
              e.preventDefault();
              setState({ show_message_modal: false });
            }}
            style={{ cursor: 'pointer' }}
          >
            <i className="fas fa-times color-blue" />
          </a>
          <div className="fs-22 bold center text-mine-shaft-o-64">{ !show_partners_select ? 'Üzenet mindenkinek' : 'Üzenet' }</div>
          { !show_partners_select ? null : (
            <Select
              name="partners-select"
              options={partners}
              isMulti
              isSearchable
              onChange={e => update_recipients(e)}
            />
          )}
          <input
            className="input mt2 mb2"
            type="text"
            placeholder="Üzenet tárgya..."
            value={subject}
            onChange={e => update_subject(e.target.value)}
            style={{
              padding: '1rem',
            }}
          />
          {/* <textarea
            className="textarea col-12 my2 p2"
            placeholder="Üzenet tartalma..."
            rows="10"
            value={message}
            onChange={e => update_message(e.target.value)}
            style={{
              resize: 'none',
              height: '150px',
              fontSize: 18,
            }}
          /> */}
          <div className="trix-content my2 p2 ">
            <TrixEditor
              className="trix-editor"
              onChange={handleChange}
              style={{ height: '200px', overflow: 'scroll' }}
              placeholder="Üzenet tartalma..."
            />
          </div>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone border-none' })}>
              <input {...getInputProps()} />
              <div className="dz-message data-dz-message pt-4 text-center">
                <div className="mb2">
                  <img alt="icon" src="/icons/dropzone.svg" />
                  <div>
                    <span className="text-pistachio" style={{ fontWeight: '700' }}>
                      Klikkeljen a megnyitáshoz
                    </span>
                    <span>
                      vagy húzzon ide egy fájlt!
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <aside>
              <h4>Feltöltendő fájlok</h4>
              <ul>{files}</ul>
            </aside>
          </section>
          <div className="flex flex-justify-end">
            <button
              type="button"
              className={`btn btn--primary p1 border border-athens-gray ${can_submit ? '' : 'disabled'}`}
              disabled={!can_submit}
              onClick={() => setState({ show_confirmation_modal: true })}
            >
              Küldés
            </button>
          </div>
        </div>
      </Modal>
      {
        show_confirmation_modal && <Modal
          ariaHideApp={false}
          isOpen={show_confirmation_modal}
          closeTimeoutMS={200}
          style={{
            overlay: {
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: 7,
            },
            content: {
              height: 250,
              width: '40%',
              top: '35%',
              margin: '0 auto',
            },
          }}
        >
          <div className="flex flex-column flex-justify" style={{ height: '100%' }}>
            <div className="fs-22 bold center text-mine-shaft-o-64">Biztos vagy benne, hogy { !show_partners_select ? 'mindenkinek küldesz üzenetet' : 'elküldöd az üzenetet' } ?</div>
            <div className="col-12 flex flex-justify">
              <a
                href="#closeConfirmationModal"
                className="btn btn--danger"
                onClick={(e) => {
                  e.preventDefault();
                  setState({ show_confirmation_modal: false });
                }}
              >
                Nem
              </a>
              <a
                href="#sendMassMessage"
                className="btn btn--primary"
                onClick={(e) => {
                  e.preventDefault();
                  send_message();
                }}
              >
                Igen
              </a>
            </div>
          </div>
        </Modal>
      }
    </div>
  );
}

export default MassMessage;
