import Promise from 'promise-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import ArticlesContainer from '../components/ArticlesContainer.jsx';
import PartnerSelect from '../components/PartnerSelect.jsx';
import ReportChart from '../components/ReportChart.jsx';
import MessagesContainer from '../components/MessagesContainer.jsx';
import LogsContainer from '../components/LogsContainer.jsx';
import SlaughterReports from '../components/reports/SlaughterReports.jsx';
import FileUpload from '../components/FileUpload.jsx';

// Object.assign polyfill
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target) { // .length of function is 2
      const to = Object(target);

      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }


      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true,
  });
}

if (!window.Promise) window.Promise = Promise;

// Object.values polyfill (IE does not support it)
Object.values = obj => Object.keys(obj).map(key => obj[key]);

window.React = React;
window.ReactDOM = ReactDOM;

window.ArticlesContainer = ArticlesContainer;
window.PartnerSelect = PartnerSelect;
window.ReportChart = ReportChart;
window.MessagesContainer = MessagesContainer;
window.LogsContainer = LogsContainer;
window.SlaughterReports = SlaughterReports;
window.FileUpload = FileUpload;
