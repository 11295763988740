import React from 'react';

function ReportMessageItem(props) {
  const {
    title,
    icon,
    last_message_date,
    sub_title,
    attachments,
    path,
    priority_color,
    deleted,
    read_state: {
      read_by_user,
      unread_replies_count,
    },
  } = props;
  const set_prio_color = (priority) => {
    if (priority == 1) return "#84BD00"
    if (priority == 2) return "#E98300"
    if (priority == 3) return "#BE0027"
  }
  const bg_color = deleted ? '#fadedc' : 'athens-gray';
  return (
    <div
      className={`block border-bottom border-athens-gray last-element-no-bottom-border px2 py2 pointer border-left-${priority_color}`}
      onClick={() => window.location.href = path}
      style={{
        borderLeftWidth: 6,
        borderLeftStyle: 'solid',
        backgroundColor: bg_color,
      }}
    >
      <div className="flex">
        <div style={{ minWidth: 64 }}>
          <img src={`/icons/message/${icon}`} alt="icon" />
        </div>
        <div className="flex-auto fs-13 text-mine-shaft-o-64">
          <div className="flex flex-justify sm-block">
            <p className="fs-15 text-mine-shaft">
              <span className={`${read_by_user ? '' : 'bold'}`}>{title}</span>
              {
                !!unread_replies_count && <span className="inline-block ml1">
                  <span className="notification">{unread_replies_count}</span>
                </span>
              }
            </p>
            <p dangerouslySetInnerHTML={{ __html: last_message_date }} />
          </div>
          <p className="mb1">{sub_title}</p>
          <div className="flex flex-justify flex-start fs-13 mt2">
            <div className="flex flex-wrap pr2">
              {
                !!attachments && attachments.map(({ id, filename, icon, attachment_url }) => <a
                  key={id}
                  href={attachment_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={filename}
                  onClick={e => e.stopPropagation()}
                >
                  <p className="bg-athens-gray flex flex-center lh-32 mb1 mr2 px1 rounded-custom-8 sm-lh-24 text-mine-shaft word-break-all">
                    <img className="mr1" src={`/icons/documents/${icon}`} alt="icon" />
                    { filename }
                  </p>
                </a>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportMessageItem;
